import React, { useState } from "react";
import "./AddProduct.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddProduct = () => {
  const Navigate = useNavigate();
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    price: "",
    image: null,
    stocks: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({
      ...productData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    setProductData({
      ...productData,
      image: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("name", productData.name);
    formdata.append("price", productData.price);
    formdata.append("description", productData.description);
    formdata.append("image", productData.image);
    formdata.append("stocks", productData.stocks);
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/add/product",
      method: "POST",
      data: formdata,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          Navigate("/products");
          console.log(data.data.message, "message");
        } else {
          console.log(data.data.message, "message");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="add-product-form-container">
      <h2>Add Product</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name" className="add-product-form-label">
            Name:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={productData.name}
            onChange={handleChange}
            required
            className="add-product-input"
          />
        </div>
        <div>
          <label htmlFor="description" className="add-product-form-label">
            Description:
          </label>
          <textarea
            id="description"
            name="description"
            value={productData.description}
            onChange={handleChange}
            required
            className="add-product-textarea"
          />
        </div>
        <div>
          <label htmlFor="price" className="add-product-form-label">
            Price:
          </label>
          <input
            type="number"
            id="price"
            name="price"
            value={productData.price}
            onChange={handleChange}
            required
            className="add-product-input"
          />
        </div>
        <div>
          <label htmlFor="stocks" className="add-product-form-label">
            Stocks:
          </label>
          <input
            type="text"
            id="stocks"
            name="stocks"
            value={productData.stocks}
            onChange={handleChange}
            required
            className="add-product-input"
          />
        </div>
        <div>
          <label htmlFor="image" className="add-product-form-label">
            Image:
          </label>
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={handleImageChange}
            required
            className="add-product-file-input"
          />
        </div>
        <button type="submit" className="add-product-submit-btn">
          Add Product
        </button>
      </form>
    </div>
  );
};

export default AddProduct;
